import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { LayoutFluid } from '../components'
import { ArrowRight, Circle, Truck1 } from '../components/icons'
import { When } from '../styling/when'
import { graphql, Link } from 'gatsby'
import { black } from '../styling/colors'
import { motion } from 'framer-motion'
import { useTranslation } from 'gatsby-plugin-react-i18next'
const UpperBox = styled.div`
  display: none;

  ${When.Large} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 34px;
  }
`
const Title = styled.h1`
  ${When.Large} {
    display: block;
    font-size: 48px;
    font-family: '' Poppins ', ' Noto Sans Georgian '';

    margin: 0;
  }
`
const Text = styled.p`
  ${When.Large} {
    display: block;
    font-size: 14px;
  }
`
const BigWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 24px;
  flex: 1;
  align-items: center;

  ${When.Medium} {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: row;
    /* margin-top: 100px; */
  }
`

const RightBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  width: auto;
  padding-left: 65px;
  padding-right: 65px;

  ${When.Medium} {
    width: 50%;
  }
`
const Round = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border: 1px solid #e0e0e0;
  border-radius: 50px;
  margin-bottom: 32px;
`

const LeftBox = styled.div`
  box-sizing: border-box;
  width: 80%;
  margin-top: 40px;
  overflow: hidden;
  height: 300px;

  ${When.Medium} {
    width: 50%;
    height: 240px;
  }

  ${When.Large} {
    height: 400px;
    margin-top: 0;
  }
`

const BusWrapper = styled(motion.div)`
  height: 100%;
`

const Bus = styled(Img)`
  height: 100%;
`
const TextSide = styled.div`
  box-sizing: border-box;
`
const Points = styled.div`
  display: none;

  ${When.Large} {
    display: block;
  }
`

const H = styled.h2`
  font-size: 24px;
  margin-bottom: 16px;
`
const Description = styled.p`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  ${When.Large} {
    text-align: left;
    max-width: 262px;
    width: 100%;
    margin-bottom: 16px;
  }
`

const ExploreAndArrow = styled.div`
  display: flex;
  align-items: center;
`

const ExploreWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 8px;
`

const ExploreText = styled.span`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
`

const OverlayModal = styled.div`
  position: fixed;
  background: #212121f2;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  transition: transform 400ms ease-in-out;
  transition: visibility 400ms ease-in-out, background 400ms ease-in-out;
  ${({ isOpen }) =>
    isOpen
      ? ''
      : `
background: transparent;
visibility: hidden;
`}
`

const ModalContents = styled.div`
  transition: transform 400ms ease-in-out;
  margin-left: auto;
  height: 100%;
  background: #fff;
  ${({ isOpen }) =>
    isOpen ? 'transform: translateX(0);' : 'transform: translateX(100%);'}
  ${When.Large} {
    max-width: 560px;
  }
`

const ModalHeader = styled.div`
  height: 80px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 24px 17px;
`

const ModalHeaderTitle = styled.h2`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #212121;
`
const LogisticsWrapper = styled.div`
  height: calc(100% - 80px);
  background: #fff;
  padding-right: 12px;
`

const LogisticsContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  padding-top: 24px;
  padding-bottom: 80px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #9e9e9e;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #9e9e9e;
  }
  ${When.Medium} {
    padding-top: 28px;
  }
  ${When.Large} {
    padding-top: 48px;
  }
`

const Logistic = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 450px;
  margin-top: 24px;
  flex-direction: column;
  padding-left: 32px;
  &:last-of-type {
    margin-bottom: 40px;
  }
  &:first-of-type {
    margin-top: 40px;
  }
  ${When.Medium} {
    margin-top: 32px;
    &:last-of-type {
      margin-bottom: 32px;
    }
    &:first-of-type {
      margin-top: 24px;
    }
  }
  ${When.Large} {
    margin-top: 32px;
    &:last-of-type {
      margin-bottom: 32px;
    }
    &:first-of-type {
      margin-top: 32px;
    }
  }
`

const DeliveryServiceImage = styled(Img)`
  width: 32px;
  height: 32px;
  border-radius: 100%;
`

const LogisticTitleDescContainer = styled.div`
  min-width: 240px;
  // max-width: 240px;
  display: flex;
  flex-direction: column;
`

const LogisticTitle = styled.h3`
  margin-top: 20px;
  margin-bottom: 12px;
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #212121;
`

const LogisticDescription = styled.p`
  margin: 0;
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
`

const Circle2 = styled.div`
  max-width: 64px;
  height: 64px;
  background-color: #212121;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`

const trailerAnimation = {
  initial: { x: -300 },
  animate: { x: 0, transition: { duration: 0.2, type: 'tween' } },
}

export default function Delivery({ data, location }) {
  const [modalOpen, setModalOpen] = useState(false)

  const logistics = useMemo(() => {
    return data.allStrapiLogistics.edges.map(e => e.node)
  }, [data])  
  const trailerImage = data.allFile.edges[0]?.node.childImageSharp.fluid
  const { t, i18n } = useTranslation()

  return (
    <>
      <OverlayModal
        id="overlay-modal"
        isOpen={modalOpen}
        onClickCapture={e => {
          e.persist()
          if (e.target.id === 'overlay-modal') {
            setModalOpen(false)
          }
        }}
      >
        <ModalContents isOpen={modalOpen}>
          <ModalHeader>
            <ModalHeaderTitle>{t('logistics')}</ModalHeaderTitle>
            <svg
              onClick={() => setModalOpen(false)}
              style={{ cursor: 'pointer' }}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#212121"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#212121"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </ModalHeader>
          <LogisticsWrapper>
            <LogisticsContainer>
              {/* {logistics.map(decoration => (
                <Logistic key={decoration.id}>
                  <Circle2>
                    {decoration.photo ? (
                      <DeliveryServiceImage
                        fluid={
                          decoration.photo[0].localFile.childImageSharp.fluid
                        }
                        alt={decoration.alternativeForPhoto}
                      />
                    ) : null}
                  </Circle2>
                  <LogisticTitleDescContainer>
                    <LogisticTitle>{decoration.title}</LogisticTitle>
                    <LogisticDescription>
                      {decoration.description}
                    </LogisticDescription>
                  </LogisticTitleDescContainer>
                </Logistic>
              ))} */}
                <Logistic key={logistics[0].id}>
                  <Circle2>
                    {logistics[0].photo ? (
                      <DeliveryServiceImage
                        fluid={
                          logistics[0].photo[0].localFile.childImageSharp.fluid
                        }
                        alt={logistics[0].alternativeForPhoto}
                      />
                    ) : null}
                  </Circle2>
                  <LogisticTitleDescContainer>
                    <LogisticTitle>{t("logisticsTextHeader0")}</LogisticTitle>
                    <LogisticDescription>
                      {t("logisticsTextContent0")}
                    </LogisticDescription>
                  </LogisticTitleDescContainer>
              </Logistic>
              
              <Logistic key={logistics[1].id}>
                  <Circle2>
                    {logistics[1].photo ? (
                      <DeliveryServiceImage
                        fluid={
                          logistics[1].photo[0].localFile.childImageSharp.fluid
                        }
                        alt={logistics[1].alternativeForPhoto}
                      />
                    ) : null}
                  </Circle2>
                  <LogisticTitleDescContainer>
                    <LogisticTitle>{t("logisticsTextHeader1")}</LogisticTitle>
                    <LogisticDescription>
                      {t("logisticsTextContent1")}
                    </LogisticDescription>
                  </LogisticTitleDescContainer>
              </Logistic>

              <Logistic key={logistics[2].id}>
                  <Circle2>
                    {logistics[2].photo ? (
                      <DeliveryServiceImage
                        fluid={
                          logistics[2].photo[0].localFile.childImageSharp.fluid
                        }
                        alt={logistics[2].alternativeForPhoto}
                      />
                    ) : null}
                  </Circle2>
                  <LogisticTitleDescContainer>
                    <LogisticTitle>{t("logisticsTextHeader2")}</LogisticTitle>
                    <LogisticDescription>
                      {t("logisticsTextContent2")}
                    </LogisticDescription>
                  </LogisticTitleDescContainer>
              </Logistic>

              <Logistic key={logistics[3].id}>
                  <Circle2>
                    {logistics[3].photo ? (
                      <DeliveryServiceImage
                        fluid={
                          logistics[3].photo[0].localFile.childImageSharp.fluid
                        }
                        alt={logistics[3].alternativeForPhoto}
                      />
                    ) : null}
                  </Circle2>
                  <LogisticTitleDescContainer>
                    <LogisticTitle>{t("logisticsTextHeader3")}</LogisticTitle>
                    <LogisticDescription>
                      {t("logisticsTextContent3")}
                    </LogisticDescription>
                  </LogisticTitleDescContainer>
              </Logistic>

              <Logistic key={logistics[4].id}>
                  <Circle2>
                    {logistics[4].photo ? (
                      <DeliveryServiceImage
                        fluid={
                          logistics[4].photo[0].localFile.childImageSharp.fluid
                        }
                        alt={logistics[4].alternativeForPhoto}
                      />
                    ) : null}
                  </Circle2>
                  <LogisticTitleDescContainer>
                    <LogisticTitle>{t("logisticsTextHeader4")}</LogisticTitle>
                    <LogisticDescription>
                      {t("logisticsTextContent4")}
                    </LogisticDescription>
                  </LogisticTitleDescContainer>
              </Logistic>
            </LogisticsContainer>
          </LogisticsWrapper>
        </ModalContents>
      </OverlayModal>
      <LayoutFluid location={location}>
        <UpperBox>
          <Title>{t('logistics')}</Title>
          <Text>{t('decorateInnov')}</Text>
        </UpperBox>

        <BigWrapper>
          <LeftBox>
            <BusWrapper
              variants={trailerAnimation}
              initial={'initial'}
              animate={'animate'}
            >
              <Bus
                imgStyle={{ objectFit: 'cover', objectPosition: 'right' }}
                fluid={trailerImage}
              ></Bus>
            </BusWrapper>
          </LeftBox>

          <RightBox>
            <TextSide>
              <Round>
                <Truck1 />
              </Round>
              <H>KMC {t('logistics')}</H>
              <Description>{t('logisticsShortText')}</Description>
              <ExploreAndArrow>
                <ExploreWrapper>
                  <ArrowRight stroke={black} width={4} height={8} />
                </ExploreWrapper>
                <ExploreText onClick={() => setModalOpen(true)}>
                  {t('explore')}
                </ExploreText>
              </ExploreAndArrow>
            </TextSide>
          </RightBox>
        </BigWrapper>
      </LayoutFluid>
    </>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativePath: { glob: "images/trailer.jpg" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    allStrapiLogistics(sort: { fields: orderNumber, order: ASC }) {
      edges {
        node {
          id
          photo {
            url
          }
          photo {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          description
          title
          alternativeForPhoto
        }
      }
    }
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
